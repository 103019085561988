import type { VFC } from 'react';
import React from 'react';

import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { LoadableAfterPaint } from '@confluence/loadable';
import { PageSegmentLoadStart } from '@confluence/browser-metrics';
import { APP_NAVIGATION_METRIC } from '@confluence/browser-metrics/entry-points/app-navigation.metric';

import { NavSkeleton } from './Nav3/NavSkeleton';
// WARN: import type is deleted upon TS compilation, so AppNavigation
// wrapped into Loadable remains behind the code split.
import type { AppNavigationProps } from './Nav3/AppNavigation';

export const AppNavigationBaseLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-AppNavigation" */ './Nav3/AppNavigation'))
			.AppNavigation,
	loading: NavSkeleton as any,
});

const AppNavigationBaseComponent: VFC<AppNavigationProps> = (props) => (
	<ErrorBoundary
		attribution={Attribution.DISCO}
		attributes={{
			errorBoundaryId: 'AppNavigationBaseComponent',
		}}
	>
		<PageSegmentLoadStart metric={APP_NAVIGATION_METRIC} />
		<AppNavigationBaseLoader {...props} />
	</ErrorBoundary>
);

export const AppNavigationBase = React.memo(AppNavigationBaseComponent);
